<template>
    <div id="app">
      <div id='step1' v-if='currentStep == 1'>
        <img src="../images/logo.png" alt="" class='logo'>
        <h1 class="main-title">Сделай коллегам комплимент</h1>
        <h2>Открытка ко дню Рождения</h2>
        <div class="input-wrapper">
          <input type="text" v-model="name" placeholder="Введите имя того, кого хотите поздравить" class="name-input" @input="checkInput"/>
          <span class="input-line"></span>
        </div>
        <div class='button-wrapper'>
          <button class='btn' @click.prevent='NextStep' :disabled="!isInputValid">Готово</button>
        </div>
        <a class="prevBtn" href="../postcard">
          <img src="../images/prevBtn.svg" alt="">
          Назад
        </a>
      </div>
      <div id='step2' v-if='currentStep == 2'>
        <div class='header'>
          <div>
            <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
          </div>
          <a @click.prevent='showInstruction'>
            <img src='../images/help.svg' class='help' @click='showInstruction'>
          </a>
        </div>
        <div>
          <h1 class="main-title">Генератор&nbsp;открытки</h1>
        </div>
        <div class='template content' ref="content">
          <img src="../images/birthday-template.jpg" alt="">
          <div class="name-fixed">{{ name }}</div>
        </div>
        <template v-if='!isGenerating'>
          <div class='buttons'>
            <button class='btn' @click.prevent='generateImage' v-if='!isGenerating'>Готово</button>
            <button class='btn' disabled v-else>Создание...</button>
  
            <button class='btn' @click.prevent='anotherName'>
              Выбрать другое имя
            </button>
          </div>
        </template>
  
        <div class='preloader preloader--gradient' v-else>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
        </div>
        <button type="button" class="prevBtn" @click.prevent='prevStep'>
          <img src="../images/prevBtn.svg" alt="">
          Назад
        </button>
      </div>
      <div id='step3' v-if='currentStep == 3'>
        <div class='header'>
          <div>
            <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
          </div>
          <a @click.prevent='showInstruction'>
            <img src='../images/help.svg' class='help' @click='showInstruction'>
          </a>
        </div>
        <div>
          <h1 class="main-title">Генератор&nbsp;открытки</h1>
        </div>
        <div class='content'>
          <h3>Ваша открытка успешно создана!</h3>
          <viewer :images='[generatedImage]' :options='viewerOptions'>
            <img :src="generatedImage" class='result'>
          </viewer>
          <div class='buttons'>
            <a @click.prevent='showPrintInstruction' class="print-instruction-btn">
              Инструкция для печати <img src='../images/help.svg' class='help' @click='showPrintInstruction'>
            </a>
            <a :href="generatedImage" class='btn' download='birthday-poscard.png' @click='download'>Скачать</a>
            <button class='btn' v-if="webShareApiSupported" @click.prevent='shareResult'>Поделиться с коллегами<sup>*</sup></button>
            <a href='#' @click.prevent='repeat' class='repeat'>Сделать ещё</a>
          </div>
  
          <div class='hint' v-if="webShareApiSupported">
            <sup>*</sup> Для сохранения высокого качества изображения рекомендуется делиться через электронную почту либо скачать изображение и отправить его без сжатия любым удобным способом.
          </div>
        </div>
      </div>
      <modal name="instruction" :adaptive='true' :scrollable='true' :height="'auto'">
        <a class='modal-close' @click.prevent='hideInstruction'>&times;</a>
        <div class='modal-content'>
          <center><span class="modal-title">Инструкция</span></center>
          <p>Данный генератор позволяет создать именную открытку.</p>
          <ol>
            <li>Введите имя в поле <b>&laquo;Введите имя для того, кого хотите поздравить&raquo;</b>.</li>
            <li>Можете ввести другое имя, нажав на кнопку <b>&laquo;Выбрать другое имя&raquo;</b>.</li>
            <li>Для завершения нажмите кнопку <b>&laquo;Готово&raquo;</b>.</li>
            <li>Через некоторое время&nbsp;Вы увидите результат, который можно увеличить, нажав на него.</li>
            <li>Вы&nbsp;можете скачать результат, нажав на&nbsp;кнопку <b>&laquo;Скачать&raquo;</b>.</li>
            <li>Также Вы&nbsp;можете поделиться результатом с&nbsp;коллегами и&nbsp;подарить им&nbsp;праздничное настроение уже сейчас, нажав на&nbsp;кнопку <b>&laquo;Поделиться с&nbsp;коллегами&raquo;</b> <i>(Доступно для устройств, поддерживающих такую возможность)</i>.</li>
            <li>Если хотите пересоздать открытку или создать новую, нажмите на&nbsp;ссылку <b>&laquo;Сделать ещё&raquo;</b></li>
          </ol>
          <center>
            <b>
              <p>Порадуйте себя и своих коллег!</p>
            </b>
          </center>
          <a href='#' @click.prevent='hideInstruction'>Понятно, приступим!</a>
        </div>
      </modal>
  
      <modal name="printInstruction" :adaptive='true' :scrollable='true' :height="'auto'">
        <a class='modal-close' @click.prevent='hidePrintInstruction'>&times;</a>
        <div class='modal-content'>
          <center><span class="modal-title">Инструкция для печати</span></center>
          <p><strong><u>ПК:</u></strong> Сохраните открытку себе на&nbsp;устройство. Откройте изображение в&nbsp;удобном для вас редакторе, отправьте на&nbsp;печать. Либо выберите пункт &laquo;Печать&raquo; в&nbsp;меню файла.</p>
          <p><strong><u>Мобильные устройства:</u></strong> Чтобы сохранить качество изображения, сгенерируйте открытку в&nbsp;сервисе, затем сохраните его на&nbsp;своем устройстве и&nbsp;отправьте на&nbsp;печать.</p>
          <p>Также открыткой можно поделиться через мессенджеры. Затем сохранить изображение с&nbsp;мессенджера на&nbsp;свое устройство и&nbsp;отправить на&nbsp;печать. При этом качество изображения будет хуже. При печати таким методом качество изображения теряется.</p>
          <center>
            <b>
              <p>Порадуйте себя и своих коллег!</p>
            </b>
          </center>
          <a href='#' @click.prevent='hidePrintInstruction'>Понятно, приступим!</a>
        </div>
      </modal>
    </div>
  </template>
  
  <script>
  import html2canvas from "html2canvas";
  import 'normalize.css';
  import 'viewerjs/dist/viewer.css';
  import 'vue-js-modal/dist/styles.css';
  
  export default {
    data: function () {
      return {
        currentStep: 1,
        name:'',
        isInputValid: false,
        generatedImage: null,
        isGenerating: false,
        viewerOptions: { "inline": false, "button": true, "navbar": false, "title": false, "toolbar": false, "tooltip": false, "movable": false, "zoomable": true, "rotatable": false, "scalable": false, "transition": false, "fullscreen": false, "keyboard": false }
      }
    },
    methods: {
      anotherName: function(_event) {
        this.currentStep = 1;
        this.name = '';
        this.isInputValid = false;
        if (this.$metrika) this.$metrika.reachGoal('ANOTHER_NAME_B');
      },
      prevStep: function(_event) {
        this.currentStep -= 1;
      },
      checkInput: function(_event) {
        this.isInputValid = this.name.trim().length > 0;
      },
      NextStep: function(_event) {
        if (this.isInputValid) {
          this.currentStep = 2;
        }
        if (this.$metrika) this.$metrika.reachGoal('NEXT_STEP_B');
      },
      async generateImage() {
        this.isGenerating = true;

        const element = this.$refs.content;

        try {
          const canvas = await html2canvas(element, {
            scale: 4,
          });
          const dataURL = canvas.toDataURL('image/png');
        
          this.generatedImage = dataURL;
        } finally {
          this.currentStep = 3;
          this.isGenerating = false;
        }
        if (this.$metrika) this.$metrika.reachGoal('GENERATE_IMAGE_B');
      },
      shareResult: async function() {
        const response = await fetch(this.generatedImage);
        const blob = await response.blob();
        const file = new File([blob], 'birthday-poscard.png', { type: blob.type });
        await navigator.share({
          title: 'Коллега, от души поздравляем вас с праздником! Создайте свою поздравительную открытку по ссылке https://bnphrmgroup.ru/comple. Сделайте приятно своим близким!',
          files: [file],
        });
        if (this.$metrika) this.$metrika.reachGoal('SHARE_RESULT_B');
      },
      repeat: function() {
        this.currentStep = 1;
        this.name = '';
        this.isInputValid = false;
        this.generatedImage = null;
        if (this.$metrika) this.$metrika.reachGoal('REPEAT_B');
      },
      download: function() {
        if (this.$metrika) this.$metrika.reachGoal('DOWNLOAD_B');
      },
      showInstruction() {
        this.$modal.show('instruction');
      },
      hideInstruction() {
        this.$modal.hide('instruction')
      },
  
      showPrintInstruction() {
        this.$modal.show('printInstruction');
      },
      hidePrintInstruction() {
        this.$modal.hide('printInstruction')
      },
    }, 
    computed: {
      webShareApiSupported: function() {
        return navigator.canShare
      }
    }
  }
  </script>
  
  <style scoped lang='scss'>
  #app {
    font-family: Gilroy;
    height: 100%;
  }
  
  h1, h2 {
      margin: 0;
    }
  
    h2 {
      font-size: 50px;
      line-height: 59px;
      color: #1758A8;
  
      @media (max-width: 992px) {
        font-size: 24px;
      }
    }
  
    h1 {
      font-size: 64px;
      line-height: 78px;
  
      @media (max-width: 992px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  
  #step1 {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 135px 50px 50px;
  
    height: 100%;
  
    @media (max-width: 992px) {
      padding: 20px 20px 50px;
      height: unset;
    }
  
    .logo {
      width: 258px;
      // height: 159px;
      padding-bottom: 60px;
  
      @media (max-width: 992px) {
        width: 189px;
        // height: 113px;
      }
    }
  
    .main-title {
      margin-bottom: 90px;
  
      @media (max-width: 992px) {
        margin-bottom: 35px;
      }
    }
  
    .uploadBtn {
      margin-top: 69px;
  
      width: 311px;
      height: 78.5px;
  
      background: #FFFFFF;
      border: 2px solid #4A7EBD;
      box-sizing: border-box;
      border-radius: 100px;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
  
      text-align: center;
  
      color: #4A7EBD;
  
      cursor: pointer;
  
      transition: color 0.5s ease-out, background-color 0.5s ease-out;
      
      svg {
        margin-right: 20px;
        transition: color 0.5s ease-out;
      }
  
      &:hover,
      &:focus-visible {
        background-color: #4A7EBD;
        color: white;
      }
  
      @media (max-width: 992px) {
        margin-top: 30px;
  
        width: 228px;
        height: 72px;
  
        font-size: 20px;
        line-height: 26px;
      }
    }

    .input-wrapper {
      width: 100%;
      position: relative;
      max-width: 550px;

      @media (max-width: 992px) {
        max-width: 400px;
      }

      @media (max-width: 767px) {
        max-width: 320px;
      }
    }

    .name-input {
      margin-top: 100px;
      font-size: 28px;
      line-height: 58px;
      border: 0;
      width: 100%;
      border-bottom: 1px solid #DCDCDC;
      outline: none;
      transition: border 0.5s ease-in-out;

      @media (max-width: 992px) {
        font-size: 20px;
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }

      &::placeholder {
        font-size: 28px;
        line-height: 58px;
        color: rgb(30, 30, 30);
        opacity: 0.8;

        @media (max-width: 992px) {
        font-size: 20px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      & ~ .input-line {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        background-color: #1758A8;
        transition: 0.4s;
      }

      &:focus-visible ~ .input-line {
        width: 100%;
        transition: 0.4s;
        left: 0;
      }
    }


    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
      .btn {
        box-sizing: border-box;
        border: none;
        padding: 10px;
        background: #4A7EBD;
        cursor: pointer;
        color: white;
        margin-right: 10px;
        text-align: center;
        border-radius: 100px;
        width: 200px;
        transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:hover:not([disabled]),
        &:focus-visible:not([disabled]) {
          color: #4A7EBD;
          background-color: white;
          box-shadow: inset 0 0 0 1px #4A7EBD;
        }
      }

      @media (max-width: 992px) {
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
        width: 100%;
        .btn {
          margin: 0 0 10px 0;
          padding: 10px;
          width: 200px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .prevBtn {
    position: absolute;
    right: 5%;
    bottom: 5%;
    padding: 15px 35px;
    margin: 0;
    border: 0;
    background-color: #D9D9D9;
    display: flex;
    gap: 8px;
    font-size: 20px;
    line-height: 24px;
    border-radius: 100px;
    text-decoration: none;
    color: black;
  
    @media (max-width: 992px) {
        position: unset;
        right: unset;
        bottom: unset;
        margin: 70px auto 0;
      }
  
    & img {
      transition: transform 0.3s ease-out;
    }
  
    &:hover {
      img {
        transform: translateX(-10px);
      }
    }
  }
  
  #step2, #step3 {
    padding: 30px;
  
    @media (max-width: 992px) {
      padding: 20px;
    }
  
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 40px;
  
      @media (max-width: 992px) {
        margin-bottom: 30px;
      }
  
      .logo {
        height: 65px;
        cursor: pointer;
      }
  
      .help {
        cursor: pointer;
      }
      
      @media (max-width: 992px) {
        .help {
          margin-right: 15px;
        }
      }
    }

    .main-title {
      margin: -60px 0 20px;
      text-align: center;

      @media (max-width: 992px) {
        margin: 20px 0 20px;
      } 
    }
  }
  
  #step2 {
    .range {
      max-width: 960px;
      width: 67%;
      margin: 0 auto;
    }
    
    .template {
      position: relative;
      width: 353px;
      margin: 0 auto 40px;
      img {
        position: relative;
        max-width: 100%;
      }
  
      .name-fixed {
        position: absolute;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
  
        bottom: 71.5%;
        left: 35px;
        width: 285px;
        text-align: center;
      }
  
      @media (max-width: 992px) {
        width: 335px;
      }
    }
  
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
      .btn {
        box-sizing: border-box;
        border: none;
        padding: 10px;
        background: #4A7EBD;
        cursor: pointer;
        color: white;
        margin-right: 10px;
        text-align: center;
        border-radius: 100px;
        width: 200px;
        transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          opacity: 0.5;
        }
  
        &:hover,
        &:focus-visible {
          color: #4A7EBD;
          background-color: white;
          box-shadow: inset 0 0 0 1px #4A7EBD;
        }
      }
  
      @media (max-width: 992px) {
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
        width: 100%;
        .btn {
          margin: 0 0 10px 0;
          padding: 10px;
          width: 60%;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  
  #step3 {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h3 {
      font-size: 36px;
      line-height: 46px;
      margin-top: 0;
      @media (max-width: 992px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
    h4 {
      font-size: 36px;
      line-height: 46px;
      margin: 0 0 10px 0;
      text-align: center;
      @media (max-width: 992px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
    img.result {
      margin-top: 50px;
      max-width: 250px;
      width: 100%;
      cursor: pointer;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;
      .btn {
        box-sizing: border-box;
        border: none;
        padding: 10px 10px;
        background-color: #4A7EBD;
        cursor: pointer;
        color: white;
        margin-bottom: 20px;
        text-align: center;
        text-decoration: none;
        border-radius: 100px;
        transition: color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
        &:last-child {
          margin-bottom: 0;
        }
  
        &:hover,
        &:focus-visible {
          color: #4A7EBD;
          background-color: white;
          box-shadow: inset 0 0 0 1px #4A7EBD;
        }
      }
  
      @media (max-width: 992px) {
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
        width: 100%;
        .btn {
          margin: 0 0 10px 0;
          padding: 10px;
          width: 60%;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  
    .repeat {
      position: relative;
      color: rgba(74, 126, 189, 1);
      text-align: center;
      text-decoration: none;
      max-width: 120px;
      margin: 0 auto;

      &:before {
        content: "";
        position: absolute;
        background-color: rgba(74, 126, 189, 1);
        left: 0;
        bottom: -3px;
        width: 0;
        height: 1px;
        transition: width 0.3s ease-in-out;
      }

      &:hover:before {
        width: 100%;
      }
    }
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    text-align: justify;
    ol {
      padding-left: 20px;
    }
    a {
      display: block;
      text-align: center;
      color: rgba(74, 126, 189, 1);
    }
  }
  
  .hint {
    max-width: 450px;
    width: 90%;
    text-align: justify;
    opacity: 0.5;
    padding-top: 15px;
    font-size: 75%;
    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 1px;
      background: black;
      margin-bottom: 5px;
    }
  }

  .modal-title {
    font-size: 42px;
    line-height: 68px;
    font-weight: 700;
  }
  
  .modal-close {
    cursor: pointer;
    position: absolute;
    font-size: 26px;
    line-height: 26px;
    width: 26px;
    padding: 5px;
    right: 10px;
    top: 10px;
    text-align: center;
    background: white;
    border-radius: 5px;
    border: 1px solid black;
  }
  
  $preloaderMainColor: #0053a6;
  $preloaderSecondColor: #E85412;
  $preloaderWidth: 55px;
  $preloaderHeight: $preloaderWidth;
  /*$preloaderAnimationDuration: 1.3s;
  $preloaderAnimationDelay: 30; //ms*/
  /* baidin */
  $preloaderAnimationDuration: 1.7s;
  $preloaderAnimationDelay: 150; //ms
  /* series */
  /*$preloaderAnimationDuration: .9s;
  $preloaderAnimationDelay: 100; //ms*/
  
  .preloader {
    width: $preloaderWidth;
    height: $preloaderHeight;
    margin: 20px auto;
    overflow: hidden;
    
    div {
      padding: 0;
      margin: 0;
      display: block;
      float: left;
      width: 27%;
      height: 27%;
      margin-right: 9%;
      margin-bottom: 9%;
      background-color: $preloaderMainColor;
      
      &:nth-child(3n){
        margin-right: 0;
      }
      
      span {
        display: block;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-color: $preloaderSecondColor;
        content: '';
        animation-duration: $preloaderAnimationDuration;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
      }
      
      @for $i from 1 through 9 {
        &:nth-child(#{$i}) {
          span{
            animation-delay: #{$i * $preloaderAnimationDelay}ms;
          }
        }
      }
    }
  }
  
  .preloader--gradient {
    // margin-left: calc($preloaderWidth / 2);
    div {
      span {
        animation-name: gradient;
      }
    }
  }
  
  .print-instruction-btn {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
    align-items: center;
    cursor: pointer;
  }
  
  @keyframes gradient {
      0% {opacity: 0;}
      50% {opacity: 1;}
      100% {opacity: 0;}
  }
  </style>
  
